<template>
    <div>
        <div
            v-if="show"
            id="retainable-rss-embed"
            data-rss="https://medium.com/feed/@ipbgenbi"
            data-maxcols="2"
            data-layout="grid"
            data-poststyle="inline"
            data-readmore="Read the rest"
            data-buttonclass="btn btn-primary"
        >
            <div class="py-20">
                Sedang mengambil data ....
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute(
            'src',
            'https://www.twilik.com/assets/retainable/rss-embed/retainable-rss-embed.js'
        )
        document.head.appendChild(recaptchaScript)
        this.show = true
    },
    // unmounted() {
    //     console.log('unmounted')
    // },
    data() {
        return {
            show: false,
        }
    },
    metaInfo: {
        titleTemplate: `%s - Blog`,
        htmlAttrs: {
            lang: 'id',
            amp: true,
        },
    },
}
</script>

<style>
.post-author {
    margin-bottom: 12px;
}

#rtb .container {
    background-color: white;
    margin-top: 30px !important;
    border: 1px;
    border-radius: 10px;
}

.post-title h2 {
    font-weight: bold;
    font-size: 20px;
    padding: 0 60px;
    margin-top: 20px;
}

.excerpt p {
    margin-top: 16px;
    text-align: justify;
}

.read-more {
    margin-top: 28px;
}

.read-more .btn-primary {
    font-weight: bold;
    color: white;
    background-color: rgb(13, 82, 141);
    padding: 10px;
    border: 5px;
    border-radius: 5px;
}

.rtb-col {
    padding: 60px 50px !important;
    /* background-color: brown; */
}

#rtb .post-image {
    border: 1px;
    border-radius: 12px;
}

.post-date {
    display: none !important;
}

#rtb .container h1 {
    font-weight: bold;
    font-size: 42px;
    padding-top: 40px !important;
    padding: 0 20px;
}

.rtb-pagination-container {
    display: none !important;
}

#rtb .container figure {
    text-align: center !important;
    padding-top: 20px;
}

#post-container .container img {
    display: inline-block !important;
    text-align: center !important;
    padding: 0 20px;
    max-width: 100% !important;
}

#post-container .container figcaption {
    margin-top: 16px;
}

#rtb .container p {
    margin-top: 24px;
    text-align: justify;
    padding: 0 20px;
}

#post-container.rtb-inline {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    background: hsla(0, 0%, 0%, 0.8);
    overflow: auto;
    padding-bottom: 50px;
}

#post-container.rtb-inline .rtb-close {
    display: block !important;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    top: 10px;
    right: 10px;
    color: black;
    font-size: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}
</style>
